.readme-box {
  height: auto;
  max-width: 500px;
  position: relative;
  margin: 50px auto 100px auto;
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  h2 {
    font-size: 1.5em;
    margin: 0.75em 0;
  }
  h3 {
    font-size: 1.17em;
    margin: 0.83em 0;
  }
  h4,
  p,
  blockquote,
  ul,
  fieldset,
  form,
  ol,
  dl,
  dir,
  menu {
    margin: 1.12em 0;
  }
  h5 {
    font-size: 0.83em;
    margin: 1.5em 0;
  }
  h6 {
    font-size: 0.75em;
    margin: 1.67em 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  strong {
    font-weight: bolder;
  }
}

.app-page {
  box-shadow: 0px 15px 40px 0px rgb(0 0 0 / 26%);
  padding-bottom: 100px;
  margin-bottom: 30px;
  background-color: #212121;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.app-footer {
  text-align: center;
  font-size: 12px;
  color: #4f4f4f;
  padding: 10px 0 30px 0;
  display: flex;
  flex-direction: column;
  .logo{
    svg{
      width: 100px;
      fill: rgba(255,255,255,.28);
    }
    a {
      text-decoration: none;
      display: inline-block;
      padding: 10px 20px;
      border-radius: 10px;
      transition: 0.3s all;
      &:hover {
        background-color: #3f3f3f;
        color: #666;
        box-shadow: 0px 15px 40px 0px rgb(0 0 0 / 26%);
        transform: translateY(-5px);
      }
    }
  }
  .footer-inner{
    p{
      padding: 0;
      margin: 0;
      padding: 5px 0;
      color: #777;
    }
    a{
      padding: 0 5px;
      transition: .3s all;
      &:hover{
        color: #fff;
      }
    }
  }
}

.portal-page {
  text-align: center;
  margin-bottom: 0;

  .section-header {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    .avatar {
      margin: 0 auto;
      height: 70px;
      width: 70px;
      border-radius: 100%;
      margin-bottom: 10px;
    }
    line-height:2.5;
    h2, p{
      margin: 0;
    }
  }

  .section-image{
    img{
      max-width: 100%;
      width: auto;
    }
  }

  
.link-button{
  display: block;
  text-decoration: none;
}

.link-button-box{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  border-radius: 100px;
  padding: 15px 0;
  margin: 0 10%;
  margin-bottom: 20px;
  line-height: 1.75;
  .link-icon{
    height: 28px;
    width: 28px;
    margin-right: 12px;
    border-radius: 5px;
  }
}
}

.portal-preview {
  height: 100vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #222;
  }

  /* Add a thumb */
  &::-webkit-scrollbar-thumb {
    background: #333;
  }
}

.portal-preview{
  .preview-notice{
    position: fixed;
    bottom: 25px;
    font-size: 12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0,0,0,0.8);
    border-radius: 10px;
    padding: 10px 20px;
    opacity: .8;
    color: #ff8787;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
    z-index: 20;
  }
}

@media screen and (max-width: 700px) {

  .readme-box {
    margin: 0 auto 0 auto;
    max-width: 100%;
  }

  .app-page{
    width: 100%;
    border-radius: 0;
    margin-bottom: 0;
  }
  .portal-page-container{
    min-height: 100vh;
  }
}