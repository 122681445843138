.studio-app {
  display: flex;

  .container {
    padding: 20px 40px;
    width: 1100px;
    margin: 0 auto;
  }

  .app-header-mobile {
    display: none;
  }

  .app-sidebar {
    transition: 0.3s all;
    position: sticky;
    top: 0;
    background-color: #444;
    height: 100vh;
    width: 250px;
    min-width: 200px;
    flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    overflow: auto;

    .app-sidebar-content {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
    }

    .logo {
      margin-top: 20px;
      margin-bottom: 40px;
      margin-bottom: 40px;
      img {
        height: 28px;
      }
    }

    .portal-selector {
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      background-color: #363636;
      border-radius: 10px;
      margin-bottom: 20px;
      transition: 0.3s all;
      cursor: pointer;
      .content {
        padding: 10px 0px 10px 20px;
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }
      h4 {
        margin: 0;
      }
      p {
        margin: 0;
        font-size: 12px;
        color: #666;
      }
      .selector-icon {
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        color: #999;
      }
      &:hover {
        background-color: #3a3a3a;
      }
    }

    .studio-nav-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
    }

    .studio-nav {
      &.studio-nav-footer {
      }
      .hr {
        height: 1px;
        width: 90%;
        background-color: #666;
        margin: 20px auto;
      }
      .item-wrapper {
        span {
          display: block;
          pointer-events: initial;
        }
      }
      .item {
        display: block;
        padding: 15px 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 10px;
        transition: 0.3s all;
        cursor: pointer;
        .chakra-icon {
          font-size: 20px;
          margin-right: 15px;
        }
        &:hover {
          background-color: #333;
        }
        &.active {
          background-color: #7dc1f2;
          color: #333;
        }
      }
    }
  }
  .app-main {
    flex-grow: 1;
    min-width: 1000px;
  }

  .studio-page-header {
    h2 {
      font-size: 36px;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .portal-editor {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    min-height: 100vh;
    &.container {
      padding-top: 0;
    }
    .avatar {
      height: 70px;
      width: 70px;
      border-radius: 100%;
      object-fit: cover;
    }
    .editor-page {
      width: 60%;
    }
    .mobile-preview-toggle {
      display: none;
    }
    .preview-page {
      position: sticky;
      top: 0;
      width: 34%;
      margin-left: 6%;
      min-width: 400px;
      max-width: 500px;
      flex-grow: 1;
      flex-shrink: 0;
      height: 100%;
      display: flex;
      align-items: center;
      min-height: 100vh;
      transition: 0.3s all;
      .phone-case,
      .portal-preview-body {
        height: 100%;
        width: 100%;
      }
      .phone-body {
        width: 100%;
        height: 100%;
        margin-bottom: 50px;
        .phone-case {
          border: 15px solid #111;
          border-radius: 40px;
          height: 750px;
          overflow: hidden;
          box-sizing: border-box;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
        }
        iframe {
          height: 100%;
          width: 100%;
        }
        .preview-phone-controller{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          text-align: center;
          p{
            margin-left: 10px;
            text-align: center;
            color: #6f6f6f;
            font-size: 13px;
            letter-spacing: 0.2em;
          }
        }
      }
    }
  }

  .portal-editor-container {
    .container {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .portal-editor-header {
      padding-bottom: 0;
    }
    .chakra-tabs__tab-panel {
      padding: 0 0;
      .action-bar {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .chakra-form-control {
        padding: 10px 0;
      }
    }

    .blocks-editor {
      .blocks-list {
        width: 100%;
        .block-item-box {
        }
        .block-item {
          display: flex;
          justify-content: flex-start;
          align-items: stretch;
          width: 100%;
          height: auto;
          background-color: #393939;
          border-radius: 10px;
          margin-bottom: 20px;
          overflow: hidden;
          transition: 0.3s all;
          .handle {
            flex-grow: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            width: 36px;
            background-color: #454545;
            padding: 10px;
          }
          .block-content {
            height: 100%;
            flex-grow: 1;
            .content {
              margin: 12px;
              .chakra-form-control,
              .chakra-form__label,
              .chakra-input {
                font-size: 13px;
              }
              .chakra-input {
                -webkit-padding-start: var(--readme-space-3);
                padding-inline-start: var(--readme-space-3);
                -webkit-padding-end: var(--readme-space-3);
                padding-inline-end: var(--readme-space-3);
                height: var(--readme-sizes-8);
              }
              .chakra-textarea {
                border-radius: 5px;
              }
              .chakra-table__container {
                padding: 10px 0;
                .left {
                  line-height: 32px;
                }
                .table-handle {
                  float: left;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  background-color: #454545;
                  border-radius: 5px;
                  height: 32px;
                  padding: 0 3px;
                  margin-right: 4px;
                }
                td {
                  -webkit-padding-start: var(--readme-space-2);
                  padding-inline-start: var(--readme-space-2);
                  -webkit-padding-end: var(--readme-space-2);
                  padding-inline-end: var(--readme-space-2);
                  padding-top: var(--readme-space-1);
                  padding-bottom: var(--readme-space-2);
                }
                th {
                  -webkit-padding-start: var(--readme-space-2);
                  padding-inline-start: var(--readme-space-2);
                  -webkit-padding-end: var(--readme-space-2);
                  padding-inline-end: var(--readme-space-2);
                }
                .link-icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 28px;
                  width: 28px;
                  border-radius: 5px;
                }
              }
              .table-action {
                display: flex;
                justify-content: flex-end;
                padding-right: var(--readme-space-2);
              }
              .empty-info {
                font-size: 12px;
                text-align: center;
                color: #999;
                width: 100%;
                padding: 20px 0;
              }
            }
            .header {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
      .section-image-preview {
        background-color: #333;
        width: 100%;
        height: 160px;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        color: #666;
        margin-top: 10px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .portal-style-panel {
      .style-setting {
        .item {
          margin: 18px 0;
        }
        .bg-list {
          display: flex;
          flex-wrap: wrap;
          margin: 5px 0;

          .color-item {
            border-radius: 10px;
            overflow: hidden;
            margin: 10px 0;
            margin-right: 10px;
            position: relative;
            transition: 0.3s all;
            border: transparent 4px solid;
            &.active {
              background-color: #555;
              border-color: #4da3c8;
            }
            .color,
            .img {
              height: 90px;
              width: 70px;
              float: left;
              border-radius: 10px;
            }
            &.img-item {
              overflow: visible;
            }
            .img {
              background-size: cover;
              .img-button {
                position: absolute;
                right: -10px;
                top: -10px;
                height: 24px;
                width: 24px;
                border-radius: 100%;
                background-color: #dd4b4b;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
              }
            }
            span {
              position: absolute;
              right: 0;
              bottom: 0;
              display: inline-block;
              padding: 6px 6px;
              text-align: center;
              transform: rotate(90deg) translateY(100%);
              transform-origin: bottom right;
              text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
              letter-spacing: 3px;
            }
          }
        }
      }
      h3 {
        // font-weight: normal;
        padding: 10px 0 20px 0;
      }
      .portal-style-list {
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 100%;
          background-color: #393939;
          border-radius: 10px;
          margin-bottom: 20px;
          overflow: hidden;
          transition: 0.3s all;

          .desc {
            padding: 10px 20px;
            h4 {
              font-weight: normal;
              font-size: 16px;
              margin-bottom: 3px;
            }
            p {
              font-size: 12px;
              opacity: 0.6;
            }
          }

          &.active {
            background-color: #555;
            color: #fff;
          }
        }
      }
    }
  }

  .portal-list-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .item {
      width: 49%;
      background-color: #393939;
      border-radius: 10px;
      padding: 20px 20px;
      border-width: 2px;
      border-color: transparent;
      margin-bottom: 20px;
      &.selected {
        border-color: #555;
      }
      .content {
        .tags {
          float: right;
        }
        h3 {
          margin-bottom: 6px;
        }
        p {
          font-size: 13px;
          color: #999;
        }
      }
      .action {
        margin-top: 20px;
        button {
          margin-right: 10px;
        }
      }
    }
  }

  .coming-soon-block {
    text-align: center;
    line-height: 2;
    padding: 60px 0;
    color: #999;
    background-color: #393939;
    border-radius: 20px;
    .icon {
      font-size: 140px;
      color: #666;
    }
    h2 {
      font-weight: normal;
      font-size: 32px;
    }
    p {
      font-size: 13px;
    }
    a{
      color: #ccc;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .studio-auth-page {
    .register-preview {
      margin: 0 auto;
      position: relative;
      height: 150px;
      width: 300px;
      margin-bottom: 20px;
      background-color: #333;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        opacity: 0.2;
        height: 120px;
        padding-bottom: 20px;
      }
      .link-bar {
        position: absolute;
        bottom: 20px;
        left: -15px;
        background-color: #4da3c8;
        color: rgba(255, 255, 255, 0.7);
        padding: 6px 10px;
        border-radius: 10px;
        letter-spacing: 0.07em;
        span {
          font-size: 17px;
          color: #fff;
        }
        span.placeholder {
          opacity: 0.4;
        }
      }
    }
  }

  .form-block {
    .chakra-form-control {
      padding: 10px 0;
    }
  }
}

.portal-create-section-modal {
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .item {
    width: 49%;
    background-color: #292929;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
  }
}

.portal-selector-modal {
  .portal-list-box {
    display: flex;
    flex-direction: column;
    .item {
      width: 100%;
      margin-bottom: 10px;
      background-color: #393939;
      border-radius: 10px;
      padding: 20px 20px;
      border-width: 2px;
      border-color: transparent;
      &.selected {
        border-color: #7dc1f2;
      }
      .content {
        .tags {
          float: right;
        }
        h3 {
          margin-bottom: 6px;
        }
        p {
          font-size: 13px;
          color: #999;
        }
      }
      .action {
        margin-top: 20px;
        button {
          margin-right: 10px;
        }
      }
    }
  }
}

.studio-portal-share-page {
  .lf-box {
    display: flex;
    background-color: #393939;
    border-radius: 20px;
    padding: 40px;
  }
  .left {
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 25px;
    button {
      margin-top: 20px;
      width: 150px;
    }
  }
  .content,
  .sample {
    flex-grow: 1;
    width: 50%;
  }
  .sample {
    margin-left: 5%;
  }
  .content {
    p {
      margin-bottom: 20px;
    }
    .link {
      display: inline-block;
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
      font-size: 18px;
      background-color: #222;
      padding: 10px 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      margin-right: 20px;
    }
    h3 {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
  .swiper-box {
    width: 100%;
    margin-bottom: 20px;
    .swiper-item {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      img {
        width: auto;
        display: inline-block;
      }
    }
  }
  .qrcode-box {
    // display: flex;
    // align-items: flex-end;
    .qrcode {
      height: 180px;
      width: 180px;
      border-radius: 10px;
      overflow: hidden;
      canvas {
        height: 180px !important;
        width: 180px !important;
      }
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}

.about-page{
  p{
    color: #ccc;
  }
  img{
    width: 30%;
  }
}

@media screen and (max-width: 700px) {
  .studio-app {
    .container {
      padding: 20px 5%;
      width: 100%;
      margin: 0 auto;
    }

    .app-header-mobile {
      display: block;
      background-color: #393939;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        height: 18px;
        img {
          height: 100%;
        }
      }
      .mobile-menu {
        font-size: 22px;
      }
    }

    .app-sidebar {
      transition: 0.3s all;
      // display: none;
      position: fixed;
      z-index: 999;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      padding-top: 50px;
      max-height: 0;
      &.active {
        display: block;
        max-height: 100vh;
        overflow: auto;
      }
    }
    .app-main {
      min-width: 0;
      padding-top: 50px;
    }

    .portal-editor {
      .editor-page {
        width: 100%;
        padding-bottom: 100px;
      }
      .mobile-preview-toggle {
        position: fixed;
        display: block;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        .icon {
          margin-right: 10px;
        }
      }
      .preview-page {
        position: fixed;
        transition: 0.3s opacity;
        opacity: 0;
        top: 50px;
        left: 0;
        width: 100%;
        height: 0;
        overflow: hidden;
        margin-left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        min-width: 0;
        max-width: initial;
        min-height: 0;
        margin-bottom: 100px;
        &.active {
          display: block;
          opacity: 1;
          height: 100vh;
        }

        .phone-body {
          transform: scale(0.75);
          transform-origin: center center;
          margin: 0 auto;
          width: 100vw;
          height: 85vh;
          max-width: 500px;
          .phone-case {
            height: 100%;
          }
        }
      }
    }

    .studio-portal-share-page {
      .lf-box {
        padding: 20px;
        flex-wrap: wrap;
        .sample {
          margin-left: 0;
          width: 100%;
          margin-top: 30px;
          .swiper-item {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    .portal-list-box {
      flex-wrap: wrap;
      .item {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }

  .about-page{
    img{
      width: 100%;
    }
  }
}

.croper-img-dialog {
  .croper-img-info {
    padding-left: 20px;
  }
  .croper-img-preview {
    background-color: #f5f5f5;
    height: 200px;
    max-width: 300px;
    overflow: hidden;
  }
}
