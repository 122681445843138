body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
  color: #efefef;
  line-height: 1.8;
  font-size: 15px;
}

html,
body,
iframe {
  color-scheme: dark;
}

::selection {
  background: rgba(255,255,255,.1);
}

.container {
  padding: 20px 5%;
}

a,
a:link,
a:hover,
a:visited {
  color: inherit;
  text-decoration: none;
}

.article {
  line-height: 2;

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  h2 {
    font-size: 1.5em;
    margin: 0.75em 0;
  }
  h3 {
    font-size: 1.17em;
    margin: 0.83em 0;
  }
  h4,
  p,
  blockquote,
  ul,
  fieldset,
  form,
  ol,
  dl,
  dir,
  menu {
    margin: 1.12em 0;
  }
  h5 {
    font-size: 0.83em;
    margin: 1.5em 0;
  }
  h6 {
    font-size: 0.75em;
    margin: 1.67em 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  strong {
    font-weight: bolder;
  }

  ol,li{
    list-style-position: inside;
  }

  a,
  a:link,
  a:hover,
  a:visited {
    color: inherit;
    text-decoration: none;
    color: #8fd9f9;
    transition: 0.3s all;
  }

  a:link:after {
    content: "";
    display: inline-block;
    background: url(~/public/statics/images/link-icon.png);
    background-size: 100% 100%;
    height: 0.7em;
    width: 0.7em;
    margin-left: 0.26em;
    margin-bottom: 0.2em;
    vertical-align: middle;
  }

  a:hover {
    &::after {
      text-decoration: underline;
      opacity: 0.8;
    }
    text-decoration: underline;
    color: #4da3c8;
  }
}

.empty-block {
  text-align: center;
  margin: 100px 0;
  .icon {
    font-size: 80px;
    color: #999;
  }
  .content {
    margin: 40px 0;
  }
}

.common-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  p{
    margin-top: 10px;
  }
}
