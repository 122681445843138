.portal-page{
  padding-bottom: 0;
  *{
    transition: .1s all;
  }
}


.portal-page-container{
  background: #333 center center no-repeat cover;
  background-size: cover;
  background-attachment: fixed;

  .portal-page-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    z-index: 1;
    background-size: cover;
    background-attachment: fixed;
    .portal-page-background-mask{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-attachment: fixed;
      background-color: transparent;
    }
  }
  
  > *{
    position: relative;
    z-index: 5;
  }

}

.portal-theme-default{

  background-color: #3a3a3a;
  
  .portal-page-background {
    .portal-page-background-mask{
      background-color: rgba(0,0,0,.5);
    }
  }
}

.portal-theme-light{
  background-color: #e9e9e9;
  color: #333;
  .link-button-box{
    background-color: rgba(255,255,255,.7);
    color: #333;
  }
  .app-footer{
    svg{
      fill: rgba(0,0,0,.22);
    }
    &:hover a{
      background-color: #fff;
    }
  }
  .portal-page-background {
    .portal-page-background-mask{
      background-color: rgba(255,255,255,.4);
    }
  }
}

.portal-theme-transparent{
  .app-footer{
    svg{
      fill: rgba(255,255,255,.32);
    }
    &:hover a{
      background-color: rgba(0,0,0,.4);
    }
  }
  .portal-page-background {
    background-image: url("//q.cdn.readme.cool/statics/portals/background-t.jpg");
  }
}